import React from 'react';
import './WhatsAppButton.css';
import whatsappImage from '../assets/whatsapp.png'; // Correctly import the image

const WhatsAppButton = () => {
    return (
        <div className="navbar-whatsapp">
            <a 
                href="https://wa.me/254724118790?text=Hello,%20I%27m%20interested%20in%20your%20IT%20products%20such%20as%20laptops,%20computer%20accessories,%20Windows,%20Office,%20antivirus,%20and%20more.%20Can%20you%20provide%20more%20details?" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="whatsapp-link"
            >
                <img 
                    src={whatsappImage} 
                    alt="WhatsApp" 
                    className="whatsapp-image"
                />
            </a>
        </div>
    );
}

export default WhatsAppButton;
