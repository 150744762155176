import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

function HomePage() {
  const [products, setProducts] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [error, setError] = useState(null);
  const [overflowingCategories, setOverflowingCategories] = useState({});
  const [visibleArrows, setVisibleArrows] = useState({});
  const productRefs = useRef({});
  const [cartAlert, setCartAlert] = useState(""); 
  const [loading, setLoading] = useState(false); 

  // Scroll to top when the page is loaded
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, []); // Empty dependency array ensures this only runs on initial load

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://admin.budtechenterprise.co.ke/api/products/');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setProducts(data);
        setIsLoading(false); // Set loading to false once products are fetched
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load product data. Please try again later.');
        setIsLoading(false); // Ensure loading is turned off on error
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (Object.keys(products).length > 0) {
      Object.keys(productRefs.current).forEach((category) => {
        const productList = productRefs.current[category];
        if (productList) {
          const isOverflowing = productList.scrollWidth > productList.clientWidth;
          setOverflowingCategories((prev) => ({
            ...prev,
            [category]: isOverflowing,
          }));
          setVisibleArrows((prev) => ({
            ...prev,
            [category]: {
              left: productList.scrollLeft > 0,
              right:
                productList.scrollWidth > productList.clientWidth &&
                productList.scrollLeft < productList.scrollWidth - productList.clientWidth,
            },
          }));
        }
      });
    }
  }, [products]);


  const addToCart = (product) => {
    setLoading(true); // Show the loading animation
    const cartItems = JSON.parse(localStorage.getItem("cart")) || { items: [] };
    const existingItemIndex = cartItems.items.findIndex((item) => item.product.id === product.id);

    if (existingItemIndex > -1) {
      cartItems.items[existingItemIndex].quantity += 1;
    } else {
      cartItems.items.push({
        product: { id: product.id, name: product.name, price: product.price, description: product.description, image: product.image },
        quantity: 1,
      });
    }

    localStorage.setItem("cart", JSON.stringify(cartItems));

    // Triggering custom alert
    setCartAlert(`${product.name} has been added to your cart!`);

    // Force re-render by resetting the cart alert state after 3 seconds
    setTimeout(() => {
      setCartAlert("");
      setLoading(false); // Hide the loading animation after 3 seconds
    }, 3000);

    // Reload the page
    window.location.reload();
  };

  if (error) {
    return <p className="error-message">{error}</p>;
  }


  const handleScroll = (category) => {
    const productList = productRefs.current[category];
    if (productList) {
      setVisibleArrows((prev) => ({
        ...prev,
        [category]: {
          left: productList.scrollLeft > 0,
          right:
            productList.scrollWidth > productList.clientWidth &&
            productList.scrollLeft < productList.scrollWidth - productList.clientWidth,
        },
      }));
    }
  };

  const scrollLeft = (category) => {
    const productList = productRefs.current[category];
    if (productList) {
      productList.scrollBy({ left: -300, behavior: 'smooth' });
      handleScroll(category);
    }
  };

  const scrollRight = (category) => {
    const productList = productRefs.current[category];
    if (productList) {
      productList.scrollBy({ left: 300, behavior: 'smooth' });
      handleScroll(category);
    }
  };

  const renderSkeletonLoader = () => (
    <div className="skeleton-row">
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <div key={index} className="skeleton-card">
            <div className="skeleton-image"></div>
            <div className="skeleton-details">
              <div className="skeleton-text short"></div>
              <div className="skeleton-text medium"></div>
              <div className="skeleton-text long"></div>
            </div>
          </div>
        ))}
    </div>
  );

  const renderProductList = (products, category, subcategory) => (
    <div
      className="product-list"
      ref={(el) => (productRefs.current[`${category}-${subcategory}`] = el)}
      onScroll={() => handleScroll(`${category}-${subcategory}`)}
    >
      {products.length > 0 ? (
        products.map((product) => {
          const imageUrl = product.image.startsWith('http')
            ? product.image
            : `https://admin.budtechenterprise.co.ke${product.image}`;

          return (
            <div key={product.id} className="product-itemm">
              <div className="product-image-wrapper">
                <img
                  src={imageUrl}
                  alt={product.name}
                  className="product-image"
                  onError={(e) => {
                    e.target.src = 'placeholder-image.jpg';
                  }} // Fallback image
                />
              </div>
              <h3 className="product-name" title={product.name}>{product.name}</h3>
              <p className="product-description">{product.description}</p>
              <div className="price-info">
                <p className="product-pricee">Kshs. {new Intl.NumberFormat().format(product.price)}</p>
                {product.was_price && (
                  <p className="was-pricee">
                    <span className="strikethrough">Kshs.{new Intl.NumberFormat().format(product.was_price)}</span>
                  </p>
                )}
              </div>
              <Link
                to={`/products/${product.main_category_slug}/${product.subcategory_slug}/${product.sub_subcategory_slug}/${product.slug}/`}
                className="view-details-button"
              >
                View Details
              </Link>
              <button
                  className="add-to-cart-icon"
                  onClick={() => addToCart(product)}
                >
                  🛒
              </button>
              {/* Display custom cart alert */}
              {cartAlert && <div className="cart-alert">{cartAlert}</div>}
            </div>
          );
        })
      ) : (
        <p>No products available</p>
      )}
    </div>
  );

  return (
    <div className="home-page">
      {error && <p className="error-message">{error}</p>}

      {isLoading ? (
        renderSkeletonLoader() // Display skeleton loader when fetching
      ) : Object.keys(products).length > 0 ? (
        Object.keys(products).map((category) => (
          <section
            key={category}
            className={`product-category ${category.toLowerCase().replace(/\s+/g, '-')}`}
          >
            <h2>{category}</h2>

            {Object.keys(products[category]).map((subcategory) => (
              <div key={subcategory} className="subcategory">
                <h3>{subcategory}</h3>

                {overflowingCategories[`${category}-${subcategory}`] &&
                  visibleArrows[`${category}-${subcategory}`]?.left && (
                    <button
                      className="scroll-button left"
                      onClick={() => scrollLeft(`${category}-${subcategory}`)}
                    >
                      &#8592;
                    </button>
                  )}

                {renderProductList(products[category][subcategory], category, subcategory)}

                {overflowingCategories[`${category}-${subcategory}`] &&
                  visibleArrows[`${category}-${subcategory}`]?.right && (
                    <button
                      className="scroll-button right"
                      onClick={() => scrollRight(`${category}-${subcategory}`)}
                    >
                      &#8594;
                    </button>
                  )}
              </div>
            ))}
          </section>
        ))
      ) : (
        <p>No product categories available.</p>
      )}
    </div>
  );
}

export default HomePage;
