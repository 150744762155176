// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import './Auth.css';  // Import CSS for styling

const Login = () => {
  const [username, setUsername] = useState(''); // Changed to username
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.budtechenterprise.co.ke/api/users/login/', {
        username, // Changed to username
        password,
      });
      localStorage.setItem('token', response.data.token); // Save token to local storage
      // Handle successful login (e.g., redirect to homepage)
      window.location.href = '/'; // Redirect to homepage
    } catch (err) {
      setError('Login failed');
    }
  };

  return (
    <div className="login-centered-container">
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="login-input-row">
            <input
              type="text" // Changed to text for username
              placeholder="Username" // Changed placeholder to Username
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="login-input-row">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          <button type="submit">Login</button>
        </form>
        <div className="signup-link">
          <p>Don't have an account? <Link to="/signup">Sign Up</Link> today</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
