import React, { useEffect } from 'react';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import L from 'leaflet'; // Import Leaflet JS
import './Footer.css'; // Your existing CSS
import logoImage from '../assets/blogo.png'; // Adjust path to logo
import backgroundImage from '../assets/footer-bg.jpg'; // Background image

const Footer = () => {
  useEffect(() => {
    // Initialize Leaflet map
    const map = L.map('map').setView([-1.2820194, 36.8221601], 17); // Coordinates for Budtech

    // Add OpenStreetMap tiles
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    // Add a marker
    L.marker([-1.2820194, 36.8221601])
      .addTo(map)
      .bindPopup('Budtech Enterprises')
      .openPopup();
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h3>About Us</h3>
          <a href="/" className="navbar-logo">
            <img src={logoImage} alt="Logo" className="logo-image" />
          </a>
          <p>
            Our goal is to ensure that our customers get the best tech products
            and services to meet their needs.
          </p>
        </div>
        <div className="footer-column">
          <h3>Contact</h3>
          <ul className="contact-info">
            <li>Email: <a href="mailto:info@budtechenterprises.com">info@budtechenterprise.com</a></li>
            <li>WhatsApp: <a
              href="https://wa.me/254724118790?text=Hello,%20I%27m%20interested%20in%20your%20IT%20products%20such%20as%20laptops,%20computer%20accessories,%20Windows,%20Office,%20antivirus,%20and%20more.%20Can%20you%20provide%20more%20details?"
              target="_blank"
              rel="noopener noreferrer"
            >+254 724 118 790</a></li>
            <li>Phone: <a href="tel:+254724118790">+254 724 118 790</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Location</h3>
          <div id="map" style={{ width: '300px', height: '200px', borderRadius: '8px', overflow: 'hidden' }}></div>
          <p>
            <a
              href="https://maps.app.goo.gl/UPEKsBd4js65gYAD6"
              target="_blank"
              rel="noopener noreferrer"
              className="map-link"
            >
              View on Google Maps
            </a>
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Budtech Enterprises. All rights reserved.</p>
        <ul className="footer-menu">
          <li><a href="/privacy">Privacy Policy</a></li>
          <li><a href="/terms">Terms of Service</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
