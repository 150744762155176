import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : { items: [] };
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  // Add product to cart, ensuring that the product description is included
  const addToCart = (product) => {
    setCart((prev) => {
      const existingProductIndex = prev.items.findIndex(
        (cartItem) => cartItem.product.id === product.id
      );

      if (existingProductIndex > -1) {
        // Product exists, update the quantity
        const updatedItems = [...prev.items];
        updatedItems[existingProductIndex].quantity += 1;
        return { items: updatedItems };
      } else {
        // Add product with all details (including description) to the cart
        return {
          items: [
            ...prev.items,
            { product: { ...product, description: product.description }, quantity: 1, productVersion: Date.now() },
          ],
        };
      }
    });
  };

  const updateCart = (productId, quantity) => {
    setCart((prevCart) => {
      const updatedItems = prevCart.items
        .map((item) => {
          if (item.product.id === productId) {
            return { ...item, quantity: Math.max(1, quantity) };
          }
          return item;
        })
        .filter((item) => item.quantity > 0);

      return { items: updatedItems };
    });
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => {
      const updatedItems = prevCart.items.filter(item => item.product.id !== productId);
      return { items: updatedItems };
    });
  };

  const cartCount = cart.items.reduce((total, item) => total + item.quantity, 0);

  return (
    <CartContext.Provider value={{ cartCount, addToCart, updateCart, removeFromCart, cart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
