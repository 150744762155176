import React, { useState, useEffect } from 'react';
import { useCart } from '../contexts/CartContext';
import './Cart.css';

function Cart() {
  const { cart, updateCart, removeFromCart } = useCart(); // Now we have removeFromCart
  const [animations, setAnimations] = useState({}); // To manage the animation state for each item

  // Scroll to the top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  // If cart is empty, show a more engaging message
  if (!cart || !cart.items || cart.items.length === 0) {
    return (
      <div className="empty-cart">
        <h1>Your cart is empty</h1>
        <p>Oops! It looks like you haven't added anything to your cart yet.</p>
        <p>Browse our store to find something you'll love!</p>
        <button className="shop-now-button" onClick={() => window.location.href = '/'}>
          Shop Now
        </button>
      </div>
    );
  }

  // Calculate total amount
  const totalAmount = cart.items.reduce((total, item) => {
    return total + item.product.price * item.quantity;
  }, 0);

  // Handle the removal and animation state
  const handleRemove = (productId) => {
    // Start animation for the item being removed
    setAnimations((prev) => ({
      ...prev,
      [productId]: true,
    }));

    // After animation duration, remove the item from the cart
    setTimeout(() => {
      removeFromCart(productId); // After animation, remove the item from the cart
      setAnimations((prev) => ({
        ...prev,
        [productId]: false,
      })); // Reset animation state for that item
    }, 2000); // Duration should match the animation time
  };

  return (
    <div className="cart-page">
      <h1>Your Cart</h1>
      <div className="cart-items">
        {cart.items.map((item) => (
          <div
            className={`cart-item-card ${animations[item.product.id] ? 'animating' : ''}`} // Add 'animating' class for the animation
            key={item.product.id}
          >
            <div className="cart-item-image">
              <img src={mainImageUrl(item.product.image)} alt={item.product.name} />
            </div>

            <div className="cart-item-details">
              <h2 className="cart-item-name">{item.product.name}</h2>
              <ul className="cart-item-description">
                {truncateDescription(item.product.description).map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>


            <div className="cart-item-quantity">
              <button onClick={() => updateCart(item.product.id, item.quantity - 1)}>-</button>
              <span>{item.quantity}</span>
              <button onClick={() => updateCart(item.product.id, item.quantity + 1)}>+</button>
            </div>

            <div className="cart-item-price">
              <p>Kshs. {formatPrice(item.product.price * item.quantity)}</p>
            </div>

            {/* Styled Remove Button */}
            <button
              id="delete"
              className="del-btn"
              type="button"
              aria-label="Delete"
              onClick={() => handleRemove(item.product.id)}
              data-running={animations[item.product.id]?.toString()}
            >
              <svg
                className="del-btn__icon"
                viewBox="0 0 48 48"
                width="48px"
                height="48px"
                aria-hidden="true"
              >
                <clipPath id="can-clip">
                  <rect className="del-btn__icon-can-fill" x="5" y="24" width="14" height="11" />
                </clipPath>
                <g
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  transform="translate(12,12)"
                >
                  <g className="del-btn__icon-lid">
                    <polyline points="9,5 9,1 15,1 15,5" />
                    <polyline points="4,5 20,5" />
                  </g>
                  <g className="del-btn__icon-can">
                    <g strokeWidth="0">
                      <polyline id="can-fill" points="6,10 7,23 17,23 18,10" />
                      <use clipPath="url(#can-clip)" href="#can-fill" fill="#fff" />
                    </g>
                    <polyline points="6,10 7,23 17,23 18,10" />
                  </g>
                </g>
              </svg>
              <span className="del-btn__letters" aria-hidden="true" data-anim>
                <span className="del-btn__letter-box">
                  <span className="del-btn__letter">D</span>
                </span>
                <span className="del-btn__letter-box">
                  <span className="del-btn__letter">e</span>
                </span>
                <span className="del-btn__letter-box">
                  <span className="del-btn__letter">l</span>
                </span>
                <span className="del-btn__letter-box">
                  <span className="del-btn__letter">e</span>
                </span>
                <span className="del-btn__letter-box">
                  <span className="del-btn__letter">t</span>
                </span>
                <span className="del-btn__letter-box">
                  <span className="del-btn__letter">e</span>
                </span>
              </span>
            </button>
          </div>
        ))}

        {/* Total Amount Row */}
        <div className="cart-total">
          <h2>Total</h2>
          <p>Kshs. {formatPrice(totalAmount)}</p>
        </div>
      </div>
    </div>
  );
}

function formatPrice(price) {
  const formattedPrice = typeof price === 'number' ? price : parseFloat(price);
  if (isNaN(formattedPrice)) {
    return '0.00';
  }
  // Use toLocaleString to format with commas for thousands and two decimal places
  return formattedPrice.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

function mainImageUrl(imageUrl) {
  return imageUrl.startsWith('http') ? imageUrl : `https://admin.budtechenterprise.co.ke${imageUrl}`;
}

function truncateDescription(description) {
  if (!description) return [];

  // Split by commas to get individual points
  const points = description.split(',').map(point => point.trim()).filter(point => point.trim() !== '');

  // Split each point by periods to treat sentences separately
  const sentencePoints = points.flatMap(point => point.split('.').map(sentence => sentence.trim()).filter(sentence => sentence.trim() !== ''));

  // Limit to the first two points and truncate if necessary
  return sentencePoints.slice(0, 2).map((point, index) => 
    point.length > 50 ? point.slice(0, 50) + '...' : point // Truncate if longer than 50 chars
  );
}


export default Cart;
