import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useCart } from '../contexts/CartContext';
import './Navbar.css';
import logoImage from '../assets/logo.png';

const Navbar = () => {
  const { user } = useUser();
  const { cartCount } = useCart();
  const [menuOpen, setMenuOpen] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const categories = [
    {
      name: "Laptops",
      subcategories: [
        { name: "Brand New", subSubcategories: ["HP", "Dell", "Lenovo", "Asus", "MacBook"] },
        { name: "Refurbished", subSubcategories: ["HP", "Dell", "Lenovo"] },
        { name: "Ex UK", subSubcategories: ["HP", "Dell", "Lenovo"] },
      ],
    },
    {
      name: "Computer Accessories",
      subcategories: [
        { name: "Speakers" },
        { name: "Power Supplies" },
        { name: "USB Hubs" },
        { name: "External Drives" },
        { name: "Printers" },
        { name: "Microphones" },
        { name: "Headphones" },
        { name: "Webcams" },
        { name: "Monitors" },
        { name: "Mice" },
        { name: "Keyboards" },
      ],
    },
    { name: "Antivirus", 
      subcategories: [
        {name: "McAfee"},
        {name: "Bitdefender"},
        {name: "Kaspersky"},
        {name: "Norton Antivirus"},
        {name: "AVG Defender"}
      ] },
    {
      name: "Desktops",
      subcategories: [
        { name: "Brand New", subSubcategories: ["HP", "Dell", "Lenovo"] },
      ],
    },
    { name: "Microsoft Office", subcategories: [] },
    { name: "Operating Systems", subcategories: [] },
  ];

  const toggleDropdown = (category) => {
    setMenuOpen(menuOpen === category ? null : category);
  };

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    toggleDropdown(category);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?search=${encodeURIComponent(searchQuery)}`);
    } else {
      alert("Please enter a search term.");
    }
  };

  return (
    <div className="navbar">
      <div className="navbar-top">
        <div className="welcome-message">
          Welcome to Budtech Enterprises! For inquiries, call: <strong>+254 724 118 790</strong>
        </div>
      </div>
      <div className="navbar-middle">
        <Link to="/" className="navbar-logo">
          <img src={logoImage} alt="Logo" className="logo-image" />
        </Link>
        <form onSubmit={handleSearchSubmit} className="navbar-search">
          <input
            type="text"
            placeholder="Search for products..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </form>
        <div className="navbar-icons">
          <div className="icon-item">
            <Link to="/cart" className="icon">
              🛒<span className="icon-label">Cart ({cartCount})</span>
            </Link>
          </div>
          <div className="icon-item">
            <Link to="/wishlist" className="icon">
              ❤️<span className="icon-label">Wishlist</span>
            </Link>
          </div>
          {user ? (
            <div className="icon-item">
              <Link to="/profile" className="icon">
                👤<span className="icon-label">Hello, {user.first_name}</span>
              </Link>
            </div>
          ) : (
            <div className="icon-item">
              <Link to="/login" className="icon">
                👤<span className="icon-label">My Account</span>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="navbar-menu">
        <ul>
          <li><Link to="/">All Products</Link></li>
          {categories.map((category) => (
            <li className="dropdown" key={category.name}>
              <div
                onClick={() => handleCategoryClick(category.name)}
                className={`dropdown-toggle ${
                  activeCategory === category.name ? 'active' : ''
                }`}
              >
                {category.name} <span className="arrow">{menuOpen === category.name ? '▲' : '▼'}</span>
              </div>
              {menuOpen === category.name && (
                <ul className="dropdown-menu">
                  {category.subcategories.map((subcategory) => (
                    <li key={subcategory.name}>
                      <Link to={`/category/${category.name.toLowerCase().replace(/\s+/g, '-')}/${subcategory.name.toLowerCase().replace(/\s+/g, '-')}`}>
                        {subcategory.name}
                      </Link>
                      {subcategory.subSubcategories && (
                        <ul>
                          {subcategory.subSubcategories.map((subSubcategory) => (
                            <li key={subSubcategory}>
                              <Link
                                to={`/category/${category.name.toLowerCase().replace(/\s+/g, '-')}/${subcategory.name.toLowerCase().replace(/\s+/g, '-')}/${subSubcategory.toLowerCase().replace(/\s+/g, '-')}`}
                              >
                                {subSubcategory}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
