import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { Link } from 'react-router-dom';  // Import Link for routing
import './Auth.css';  // Import CSS for styling

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [location, setLocation] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const formData = new FormData();
    formData.append('username', username);
    formData.append('first_name', firstName);
    formData.append('second_name', secondName);
    formData.append('email', email);
    formData.append('phone_number', phoneNumber);
    formData.append('address', address);
    formData.append('location', location);
    formData.append('profile_picture', profilePicture); // Ensure this is a File object
    formData.append('password', password);

    try {
      const response = await fetch('http://127.0.0.1:8000/api/users/register/', { 
        method: 'POST',
        body: formData,  // Use formData here
        // Do not set Content-Type header manually when using FormData
      });

      if (!response.ok) {
        const errorData = await response.text(); // Read response as text
        console.error('Error response:', errorData);
        setError(`Error: ${errorData}`);
      } else {
        const data = await response.json(); // Response body is expected to be JSON
        console.log('Success:', data);
        navigate('/login'); // Redirect to login page on success
      }
    } catch (error) {
      setError(`Error: ${error.message}`);
    }
  };

  return (
    <div className="centered-container">
      <div className="auth-form">
        <h2>Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-row">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Second Name"
              value={secondName}
              onChange={(e) => setSecondName(e.target.value)}
              required
            />
          </div>
          <div className="input-row">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          <div className="input-row">
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          </div>
          <div className="input-row">
            <input
              type="file"
              onChange={(e) => setProfilePicture(e.target.files[0])}
              required
            />
          </div>
          <div className="input-row">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {error && <div className="error">{error}</div>}
          <button type="submit">Sign Up</button>
        </form>
        <div className="login-link">
          <p>Already have an account?</p>
          <Link to="/login">Login</Link> {/* Link to Login page */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
