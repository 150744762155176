import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import './SearchResults.css';

const SearchResults = () => {
  const location = useLocation();
  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const query = new URLSearchParams(location.search).get('search');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://admin.budtechenterprise.co.ke/api/products/?search=${query}`;
        console.log(`Fetching from: ${apiUrl}`);
        const response = await axios.get(apiUrl);
        console.log("Response:", response.data);
        setProducts(response.data); // Set the grouped products data
      } catch (error) {
        setError('Failed to load search results. Please try again later.');
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    if (query) {
      fetchData();
    }
  }, [query]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="search-results home-page">
      <h2>Search Results for: <span className="query">{query}</span></h2>
      {error && <p className="error-message">{error}</p>}
      {Object.keys(products).length > 0 ? (
        Object.keys(products).map(mainCategory => (
          <div key={mainCategory} className="category-group">
            <h3>{mainCategory}</h3>
            {Object.keys(products[mainCategory]).map(subcategory => (
              <div key={subcategory} className="subcategory-group">
                <h4>{subcategory}</h4>
                <div className="product-list">
                  {products[mainCategory][subcategory].map(product => {
                    const imageUrl = product.image.startsWith('http') ? product.image : `https://admin.budtechenterprise.co.ke${product.image}`;
                    return (
                      <div key={product.id} className="product-itemm">
                        <div className="product-image-wrapper">
                          <img
                            src={imageUrl}
                            alt={product.name}
                            className="product-image"
                            onError={(e) => { 
                              e.target.src = 'placeholder-image.jpg'; 
                            }} // Fallback image
                          />
                        </div>
                        <h3 className="product-name">{product.name}</h3>
                        <p className="product-description">{product.description}</p>
                        <div className="price-info">
                          <p className="product-pricee">Kshs. {new Intl.NumberFormat().format(product.price)}</p>
                          {product.was_price && (
                            <p className="was-pricee">
                              <span className="strikethrough">Kshs. {new Intl.NumberFormat().format(product.was_price)}</span>
                            </p>
                          )}
                        </div>
                        <Link to={`/products/${product.main_category_slug}/${product.subcategory_slug}/${product.sub_subcategory_slug}/${product.slug}/`} className="view-details-button">
                          View Details
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <p>No results found for <span className="query">{query}</span>.</p>
      )}
    </div>
  );
};

export default SearchResults;
