import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Details.css";

function Details() {
  const { main_category_slug, subcategory_slug, sub_subcategory_slug, product_slug } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cartAlert, setCartAlert] = useState(""); 
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    // Scroll to the top of the page when the Details component is mounted
    window.scrollTo(0, 0);

    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `https://admin.budtechenterprise.co.ke/api/products/${main_category_slug}/${subcategory_slug}/${sub_subcategory_slug}/${product_slug}/`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error("Error fetching product:", error);
        setError("Failed to load product details. Please try again later.");
      }
    };

    fetchProduct();
  }, [main_category_slug, subcategory_slug, sub_subcategory_slug, product_slug]);

const handleImageClick = (index) => {
  if (index === currentImageIndex) {
    // If the clicked image is already in view, open the modal
    setIsModalOpen(true);
  } else {
    // If the clicked image is not in view, set it as the current image
    setCurrentImageIndex(index);
  }
};


  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % additionalImages.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + additionalImages.length) % additionalImages.length
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("image-modal")) {
      closeModal();
    }
  };

  const addToCart = (product) => {
    setLoading(true); // Show the loading animation
    const cartItems = JSON.parse(localStorage.getItem("cart")) || { items: [] };
    const existingItemIndex = cartItems.items.findIndex((item) => item.product.id === product.id);

    if (existingItemIndex > -1) {
      cartItems.items[existingItemIndex].quantity += 1;
    } else {
      cartItems.items.push({
        product: { id: product.id, name: product.name, price: product.price, description: product.description, image: product.image },
        quantity: 1,
      });
    }

    localStorage.setItem("cart", JSON.stringify(cartItems));

    // Triggering custom alert
    setCartAlert(`${product.name} has been added to your cart!`);

    // Force re-render by resetting the cart alert state after 3 seconds
    setTimeout(() => {
      setCartAlert("");
      setLoading(false); // Hide the loading animation after 3 seconds
    }, 3000);

    // Reload the page
    window.location.reload();
  };

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!product) {
    return <p>Loading...</p>;
  }

  const mainImageUrl = product.image.startsWith("http")
    ? product.image
    : `https://admin.budtechenterprise.co.ke${product.image}`;
  const additionalImages = [
    mainImageUrl,
    ...product.additional_images.map((image) =>
      image.additional_image.startsWith("http")
        ? image.additional_image
        : `https://admin.budtechenterprise.co.ke${image.additional_image}`
    ),
  ];

  return (
    <div className="details-ccontainer">
      <div className="product-ddetails">
        <div className="image-wwrapper">
          <img
            src={additionalImages[currentImageIndex]}
            alt={product.name}
            className="main-iimage"
            onClick={() => handleImageClick(currentImageIndex)}
          />
          <div className="additional-iimages">
            {additionalImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Additional ${index + 1}`}
                className="additional-iimage"
                onClick={() => handleImageClick(index)}
              />
            ))}
          </div>
        </div>
        <div className="product-iinfo">
          <h1 className="product-ttitle">{product.name}</h1>
          <p className="product-ddescription">{product.description}</p>
          <div className="price-iinfo">
            <p className="product-pprice">Kshs. {new Intl.NumberFormat().format(product.price)}</p>
            {product.was_price && (
              <p className="was-pprice">
                <span className="sstrikethrough"> Kshs. {new Intl.NumberFormat().format(product.was_price)}</span>
              </p>
            )}
          </div>
          <p className="product-sstock">Stock: {product.stock}</p>
          <p className="product-wwarranty">Warranty: {product.warranty_period}</p>

          {product.laptop_features && Object.keys(product.laptop_features).length > 0 && (
            <>
              <h3 className="feature-ttitle">Features:</h3>
              <ul className="feature-llist">
                <li>Processor: {product.laptop_features.processor}</li>
                <li>RAM: {product.laptop_features.ram}</li>
                <li>Storage: {product.laptop_features.storage}</li>
                <li>Screen Size: {product.laptop_features.screen_size}</li>
                <li>Operating System: {product.laptop_features.operating_system}</li>
                <li>Graphics Card: {product.laptop_features.graphics_card}</li>
              </ul>
            </>
          )}
        </div>
      </div>
      <button className="add-to-cart-bbutton" onClick={() => addToCart(product)}>
        Add to Cart
      </button>
      <button className="back-bbutton" onClick={() => window.history.back()}>
        Back
      </button>

      {/* Display custom cart alert */}
      {cartAlert && <div className="cart-alert">{cartAlert}</div>}

      {/* Display loading animation if loading is true */}
      {loading && (
        <div className="loading-animation">
          {/* Your custom SVG animation */}
          <div className="scene">
            <svg viewBox="0 0 100 100" width="50" height="50">
              <g>
                <circle cx="50" cy="50" r="30" fill="#F5A623">
                  <animate attributeName="r" from="30" to="25" dur="0.3s" begin="0s" repeatCount="indefinite" />
                </circle>
                <circle cx="50" cy="50" r="10" fill="#D02D29">
                  <animate attributeName="r" from="10" to="5" dur="0.3s" begin="0.3s" repeatCount="indefinite" />
                </circle>
              </g>
            </svg>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="image-modal" onClick={handleOutsideClick}>
          <div className="modal-content">
            <button className="close-modal" onClick={closeModal}>
              &times;
            </button>
            <button className="prev-image" onClick={handlePrevImage}>
              &lt;
            </button>
            <img
              src={additionalImages[currentImageIndex]}
              alt={`Large ${currentImageIndex + 1}`}
              className="large-image"
            />
            <button className="next-image" onClick={handleNextImage}>
              &gt;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Details;
