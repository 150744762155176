import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './CategoryPage.css';
import './subsubcategory-page.css'

function CategoryPage() {
  const { main_category_slug, subcategory_slug } = useParams(); // Extract slugs from URL
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`https://admin.budtechenterprise.co.ke/api/products/${main_category_slug}/${subcategory_slug}/`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        // Set products from the API response
        setProducts(data.products);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load product data. Please try again later.');
      }
    };

    fetchProducts();
  }, [main_category_slug, subcategory_slug]); // Fetch products when slugs change

  const renderProductList = (products) => (
    <div className="product-list">
      {products.length > 0 ? (
        products.map(product => {
          const imageUrl = product.image.startsWith('http') ? product.image : `https://admin.budtechenterprise.co.ke${product.image}`;
          return (
            <div key={product.id} className="product-itemm">
              <div className="product-image-wrapper">
                <img
                  src={imageUrl}
                  alt={product.name}
                  className="product-image"
                  onError={(e) => { 
                    e.target.src = 'placeholder-image.jpg'; 
                  }} // Fallback image
                />
              </div>
              <h3 className="product-name">{product.name}</h3>
              <p className="product-description">{product.description}</p>
              <div className="price-info">
                <p className="product-pricee">Kshs. {new Intl.NumberFormat().format(product.price)}</p>
                {product.was_price && (
                  <p className="was-pricee">
                    <span className="strikethrough">Kshs. {new Intl.NumberFormat().format(product.was_price)}</span>
                  </p>
                )}
              </div>
              <Link to={`/products/${product.main_category_slug}/${product.subcategory_slug}/${product.sub_subcategory_slug}/${product.slug}/`} className="view-details-button">
                View Details
              </Link>
            </div>
          );
        })
      ) : (
        <p>No products available for this category</p>
      )}
    </div>
  );

  return (
    <div className="subsubcategory-page">
      <h1>
        {main_category_slug} / {subcategory_slug}
      </h1>

      {error && <p className="error-message">{error}</p>}

      {products.length > 0 ? (
        renderProductList(products)
      ) : (
        <p>Loading products...</p>
      )}
    </div>
  );
}

export default CategoryPage;
