import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ProductList from './pages/ProductList';
import Cart from './pages/Cart';
import SignUp from './components/SignUp';
import Login from './components/Login';
import CategoryPage from './pages/CategoryPage';
import SubSubCategoryPage from './pages/SubSubCategoryPage';
import Details from './pages/Details';
import SearchResults from './pages/SearchResults';
import WhatsAppButton from './components/WhatsAppButton';
import './App.css'; // Import your main CSS file
import { CartProvider } from './contexts/CartContext'; // Import CartContext

function App() {
  return (
    <CartProvider> {/* Wrap your app in CartProvider for cart functionality */}
      <div>
        <Navbar /> {/* Navbar component is now wrapped */}
        <WhatsAppButton />
        <main className="main-content"> {/* Add class for styling */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<ProductList />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/category/:main_category_slug/:subcategory_slug" element={<CategoryPage />} />
            <Route
              path="/products/:main_category_slug/:subcategory_slug/:sub_subcategory_slug/:product_slug/"
              element={<Details />}
            />6u7
            <Route
              path="/category/:main_category_slug/:subcategory_slug/:sub_subcategory_slug/"
              element={<SubSubCategoryPage />}
            />
            <Route path="/search" element={<SearchResults />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </CartProvider>
  );
}

export default App;
